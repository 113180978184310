.timeout-modal .ant-modal-content {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 15px;
  width: 400px;
}

.timeout-modal{
  top: calc(50% - 400px);
}
