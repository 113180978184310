.user-filters .led-red {
  background-color: #ed1a3b;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -0.5px 3px 0.5px, inset #ed1a3b 0 -0.5px 4px, rgba(255, 0, 0, 0.5) 0 1px 6px;
  
}


.user-filters input[type='checkbox'].checkbox::before {
  top: -8px !important;
}