@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'ProximaNova';
  src: url('../public/proximanova-regular.woff');
  font-weight: normal; /* Specify font weight if needed */
  font-style: normal; /* Specify font style if needed */
}

.proximaNova {
  font-family: 'ProximaNova';
}

.accordionReportsHover:not(.text-white):hover {
  color: #ed1a3b !important;
  background-color: #fff2ee !important;
}

.viewsContainer {
  width: calc(100% - 54px);
}

.general-shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.scroll-thin {
  scrollbar-width: thin;
}

input[type='checkbox'].checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #ccc;
  background-color: #fff;
}

input[type='checkbox'].checkbox:checked {
  border-color: red;
  background-color: red;
}

input[type='checkbox'].checkbox::before {
  content: '';
  display: inline-block;
  width: 9px;
  height: 5px;
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  transform: rotate(315deg);
  position: relative;
  left: 2px;
  top: -10px;
}

.btn-regular {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-width: 1px;
  gap: 8px;
  border-radius: 4px;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

.pressed {
  background-color: #fff;
  color: #e81a3b;
  border-color: #e81a3b;
}

.unpressed {
  background-color: #ed1a3b;
  color: #fff;
}

.unpressed:hover {
}
