@font-face {
  font-family: 'ProximaNova';
  src: url('./../../../public/proximanova-regular.woff');
  font-weight: normal; /* Specify font weight if needed */
  font-style: normal; /* Specify font style if needed */
}


.switch-toggle span {
  font-family: 'ProximaNova';
  font-size: 14px;
  /* font-weight: bold; */
}

.toggle-checkbox {
  display: none;
}



.toggle-inner {
  transition: margin 0.2s ease-in-out;
}

.toggle-text {
  width: 50%;
  pointer-events: none;
}

.toggle-switch {
  /* background: url('/public/flag-fr.png') center/cover no-repeat; */
  transition: left 0.3s ease-in-out;
}

.toggle-checkbox:checked + .toggle-label .toggle-inner {
  margin-left: -100%;
}

.toggle-checkbox:checked + .toggle-label .toggle-switch {
  left: 28px;
}