@font-face {
  font-family: 'ProximaNova';
  src: url('./../../../public/proximanova-regular.woff');
  font-weight: normal; /* Specify font weight if needed */
  font-style: normal; /* Specify font style if needed */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* ----------------STYLE TABLE */
.ant-pagination-options .ant-select-selector {
  border: 0px !important;
  cursor: pointer !important;
  background-color: transparent !important;
}

.ant-table-pagination li {
  min-width: 30px !important;
  height: 30px !important;
  /* line-height: 28px !important; */
  border: 0.5px solid #00000024 !important;
  margin: 0px 5px 0px 5px !important;
  border-radius: 0px !important;
}

.ant-table-pagination li a {
  color: var(--textColor);
}

.ant-table-pagination li:not(.ant-pagination-item-active) a:hover,
.ant-table-pagination li.ant-pagination-prev:hover button,
.ant-table-pagination li.ant-pagination-next:hover button {
  color: var(--primaryColor);
  background-color: var(--bgHover) !important;
}

.ant-table-pagination li.ant-pagination-item-active {
  background-color: var(--primaryColor);
  border: none !important;
}

.ant-table-pagination li.ant-pagination-item-active a {
  color: #fff !important;
}

.ant-select-focused:where(.css-dev-only-do-not-override-1qfezbu).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: #1677ff;
  box-shadow: none !important;
  outline: 0;
}

.ant-table-container {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

:where(.css-dev-only-do-not-override-1qfezbu).ant-table-wrapper .ant-table-column-sorter-up.active,
:where(.css-dev-only-do-not-override-1qfezbu).ant-table-wrapper .ant-table-column-sorter-down.active {
  color: var(--primaryColor) !important;
  background-color: transparent !important;
}

.ant-table-placeholder .ant-table-cell {
  border-bottom: 0 !important;
}

.ant-table-column-sorters .ant-table-column-sorter-inner .active {
  background-color: transparent !important;
}

table thead th {
  background-color: #dddddd !important;
}

.ant-table-tbody > tr > td {
  padding: 5px 16px !important;
}
