@font-face {
  font-family: 'ProximaNova';
  src: url('/public/proximanova-regular.woff');
  font-weight: normal; /* Specify font weight if needed */
  font-style: normal; /* Specify font style if needed */
}

ul.status {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
}

.businessUnitSelect {
  font-family: 'ProximaNova';
}

.filter-container {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}


.led-red {
  background-color: #ed1a3b;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -0.5px 3px 0.5px, inset #ed1a3b 0 -0.5px 4px, rgba(255, 0, 0, 0.5) 0 1px 6px;
  
}


