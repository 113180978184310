:where(.css-dev-only-do-not-override-mzwlov).ant-collapse > .ant-collapse-item:last-child,
:where(.css-dev-only-do-not-override-mzwlov).ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 !important;
}

.ant-collapse-item.ant-collapse-item-active {
  background-color: #e7e5e5;
}

.ant-collapse-item.ant-collapse-item-active ul li.name-desc {
  color: #333;
}

.ant-collapse-content-box {
  background-color: #fffefe;
}

llapse-item:hover {
  background-color: #fff2ee;
}


/* ------------------------DELETE MODULE and EDIT MODULE ------------- */
.ant-popover-content,
.ant-popconfirm-buttons {
  font-family: "ProximaNova";
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-popover-content button,
.ant-popconfirm-buttons button {
  margin: 0;
  padding: 10px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.ant-popconfirm-buttons button:first-child,
.editModule .ant-modal-footer button:first-child {
  width: 100px;
  height: 30px;
  background-color: var(--secondaryColor);
  border: none;
  color: var(--textColor);
}

.ant-popconfirm-buttons button:last-child,
.editModule .ant-modal-footer button:last-child {
  width: 100px;
  height: 30px;
  background-color: var(--primaryColor);
  border: none;
  color: var(--white);
}

.ant-popconfirm-buttons button:first-child:hover,
.ant-popconfirm-buttons button:last-child:hover,
.editModule .ant-modal-footer button:first-child:hover,
.editModule .ant-modal-footer button:last-child:hover {
  background-color: #cccccc54 !important;
  color: var(--primaryColor) !important;
  border: 0.1px solid var(--primaryColor) !important;
}

.editModule {
  font-family: "ProximaNova";
}

.editModule .ant-modal-footer button {
  border-radius: 2px;
}

.editModule .ant-modal-footer {
  height: 60px !important;
  display: flex;
  justify-content: end;
  align-items: end;
}

.editModule .ant-select {
  border: 0.5px solid #00000026;
  width: 100%;
  height: 30px;
  cursor: pointer;
  padding: 0px 5px;
}

.ant-select-selector {
  border: 0px !important;
  cursor: pointer !important;
}

.editModule select:focus-visible {
  border: none;
}

.editModule select .editModule select:focus-visible option {
  border-radius: 0%;
}

.ant-select-selector {
  box-shadow: none !important;
}

.group-assignment .ant-table-container{
 box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.ant-table-body{
  scrollbar-width: thin;
}

.assignment-user-group .ant-table-body{
  height: 350px;
}