.FrequentlyAccessed .log-table .ant-table-container {
  box-shadow: none !important;
  border-radius: 0px !important;
      border-bottom: 0.1px solid #dddddd;
    border-left: 0.1px solid #dddddd;
}

.FrequentlyAccessed :where(.css-dev-only-do-not-override-mzwlov).ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child {
    border-start-start-radius: 0px !important;
}


.FrequentlyAccessed :where(.css-dev-only-do-not-override-mzwlov).ant-table-wrapper .ant-table .ant-table-header {
    border-radius: 0px 8px 0 0;
}