.addModule .ant-modal-footer button:first-child {
  width: 100px;
  height: 30px;
  background-color: var(--secondaryColor);
  border: none;
  color: var(--textColor);
}

.addModule .ant-modal-footer button:last-child {
  width: 100px;
  height: 30px;
  background-color: var(--primaryColor);
  border: none;
  color: var(--white);
}

.addModule .ant-modal-footer button:first-child:hover,
.addModule .ant-modal-footer button:last-child:hover {
  background-color: #cccccc54 !important;
  color: var(--primaryColor) !important;
  border: 0.1px solid var(--primaryColor) !important;
}

.addModule {
  font-family: 'ProximaNova';
}

.addModule .ant-modal-footer button {
  border-radius: 2px;
}

.addModule .ant-modal-footer {
  height: 60px !important;
  display: flex;
  justify-content: end;
  align-items: end;
}

.addModule .ant-select {
  border: 0.5px solid #00000026;
  width: 100%;
  height: 30px;
  cursor: pointer;
  padding: 0px 5px;
}

.addModule select:focus-visible {
  border: none;
}

.addModule select .addModule select:focus-visible option {
  border-radius: 0%;
}

.addModule input[type='checkbox'].checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #ccc;
  background-color: #fff;
}

.addModule input[type='checkbox'].checkbox:checked {
  border-color: red;
  background-color: red;
}

.addModule input[type='checkbox'].checkbox::before {
  content: '';
  display: inline-block;
  width: 9px;
  height: 5px;
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  transform: rotate(315deg);
  position: relative;
  left: 2px;
  top: -8px;
}

.height-modal {
  top: calc(50% - 400px);
}
