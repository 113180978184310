.moveToLeft {
  margin-left: -400px;
  transition: all 0.2s ease;
}

.moveToRight {
  margin-left: 0px;
  transition: all 0.2s ease;
}

.open {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  height: 100%;
  left: -10px;
}

.open svg {
  fill: #333;
  z-index: 8;
}

.open:hover svg {
  width: 100px;
  height: 100px;
  transition: all 0.2s ease;
  fill: red;
}

.open:hover + .moveToLeft {
  left: -400px;
}
.open:hover + .containerAccordion .w-72 {
  background-color: #e1e1e1;
}
.containerAccordion {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
  z-index: 9;
}

.scroll-thin {
  scrollbar-width: thin;
}
