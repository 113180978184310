.loadership_XJWDM {
  display: flex;
  position: relative;
  width: 35px;
  height: 60px;
}

.loadership_XJWDM div {
  position: absolute;
  width: 5px;
  height: 20px;
  background: #ea2948;
  top: 20px;
  animation: loadership_XJWDM_scale 1.8s infinite, loadership_XJWDM_fade 1.8s infinite;
  animation-timing-function: ease-in-out;
}

.loadership_XJWDM div:nth-child(1) {
  animation-delay: 0s;
  left: 0px;
}
.loadership_XJWDM div:nth-child(2) {
  animation-delay: 0.2s;
  left: 10px;
}
.loadership_XJWDM div:nth-child(3) {
  animation-delay: 0.4s;
  left: 20px;
}
.loadership_XJWDM div:nth-child(4) {
  animation-delay: 0.6s;
  left: 30px;
}

@keyframes loadership_XJWDM_scale {
  0%,
  44.44444444444445%,
  100% {
    transform: scaleY(1);
  }
  22.222222222222225% {
    transform: scaleY(3);
  }
}

@keyframes loadership_XJWDM_fade {
  0%,
  44.44444444444445%,
  100% {
    opacity: 1;
  }
  22.222222222222225% {
    opacity: 1;
  }
}

/* CIRCLE ------- */

.spinner {
   width: 20px;
   height: 20px;
   border-radius: 50%;
   border: 3.8px solid #e81a3b;
   animation: spinner-bulqg1 0.8s infinite linear alternate,
        spinner-oaa3wk 1.6s infinite linear;
}

@keyframes spinner-bulqg1 {
   0% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
   }

   12.5% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
   }

   25% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
   }

   50% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
   }

   62.5% {
      clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
   }

   75% {
      clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
   }

   100% {
      clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
   }
}

@keyframes spinner-oaa3wk {
   0% {
      transform: scaleY(1) rotate(0deg);
   }

   49.99% {
      transform: scaleY(1) rotate(135deg);
   }

   50% {
      transform: scaleY(-1) rotate(0deg);
   }

   100% {
      transform: scaleY(-1) rotate(-135deg);
   }
}