.active span {
  position: absolute;
  left: 0.5px;
  width: 7px;
  height: 50px;
}

.active {
  background-color: #3e3e3e54;
}
