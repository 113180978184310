:root {
  --primaryColor: #e81a3b;
  --secondaryColor: #cccccc;
  --thirdColor: #cccccc;
  --textBg: #f8f7f8;
  --white: #ffffff;
  --textColor: #333333;
  --bgHover: #fff2ee;
  --fontSizeRegular: 14px;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('./../../../public/proximanova-regular.woff');
  font-weight: normal; /* Specify font weight if needed */
  font-style: normal; /* Specify font style if needed */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* ----------------STYLE TABLE */
.ant-pagination-options .ant-select-selector {
  border: 0px !important;
  cursor: pointer !important;
  background-color: transparent !important;
}

.ant-table-pagination li {
  min-width: 30px !important;
  height: 30px !important;
  /* line-height: 28px !important; */
  border: 0.5px solid #00000024 !important;
  margin: 0px 5px 0px 5px !important;
  border-radius: 0px !important;
}

.ant-table-pagination li a {
  color: var(--textColor);
}

.ant-table-pagination li:not(.ant-pagination-item-active) a:hover,
.ant-table-pagination li.ant-pagination-prev:hover button,
.ant-table-pagination li.ant-pagination-next:hover button {
  color: var(--primaryColor);
  background-color: var(--bgHover) !important;
}

.ant-table-pagination li.ant-pagination-item-active {
  background-color: var(--primaryColor);
  border: none !important;
}

.ant-table-pagination li.ant-pagination-item-active a {
  color: #fff !important;
}

.ant-select-focused:where(.css-dev-only-do-not-override-1qfezbu).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: #1677ff;
  box-shadow: none !important;
  outline: 0;
}

.ant-table-container {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

:where(.css-dev-only-do-not-override-1qfezbu).ant-table-wrapper .ant-table-column-sorter-up.active,
:where(.css-dev-only-do-not-override-1qfezbu).ant-table-wrapper .ant-table-column-sorter-down.active {
  color: var(--primaryColor) !important;
  background-color: transparent !important;
}

.ant-table-placeholder .ant-table-cell {
  border-bottom: 0 !important;
}

.ant-table-column-sorters .ant-table-column-sorter-inner .active {
  background-color: transparent !important;
}

table thead th {
  background-color: #dddddd !important;
}

.ant-table-tbody > tr > td {
  padding: 5px 16px !important;
}

/* ------------------------DELETE MODULE and EDIT MODULE ------------- */
.ant-popover-content,
.ant-popconfirm-buttons {
  font-family: 'ProximaNova';
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-popover-content button,
.ant-popconfirm-buttons button {
  margin: 0;
  padding: 10px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.ant-popconfirm-buttons button:first-child,
.editModule .ant-modal-footer button:first-child {
  width: 100px;
  height: 30px;
  background-color: var(--secondaryColor);
  border: none;
  color: var(--textColor);
}

.ant-popconfirm-buttons button:last-child,
.editModule .ant-modal-footer button:last-child {
  width: 100px;
  height: 30px;
  background-color: var(--primaryColor);
  border: none;
  color: var(--white);
}

.ant-popconfirm-buttons button:first-child:hover,
.ant-popconfirm-buttons button:last-child:hover,
.editModule .ant-modal-footer button:first-child:hover,
.editModule .ant-modal-footer button:last-child:hover {
  background-color: #cccccc54 !important;
  color: var(--primaryColor) !important;
  border: 0.1px solid var(--primaryColor) !important;
}

.editModule {
  font-family: 'ProximaNova';
}

.editModule .ant-modal-footer button {
  border-radius: 2px;
}

.editModule .ant-modal-footer {
  height: 60px !important;
  display: flex;
  justify-content: end;
  align-items: end;
}

.editModule .ant-select {
  border: 0.5px solid #00000026;
  width: 100%;
  height: 30px;
  cursor: pointer;
  padding: 0px 5px;
}

.ant-select-selector {
  border: 0px !important;
  cursor: pointer !important;
}

.editModule select:focus-visible {
  border: none;
}

.editModule select .editModule select:focus-visible option {
  border-radius: 0%;
}

.ant-select-selector {
  box-shadow: none !important;
}

.group-assignment .ant-table-container {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.ant-table-body {
  scrollbar-width: thin;
}

.assignment-user-group .ant-table-body {
  height: 350px;
}

/* userandgroup assignment */

.userandgroup-assignment .ant-modal-footer button:first-child {
  width: 100px;
  height: 30px;
  background-color: var(--secondaryColor);
  border: none;
  color: var(--textColor);
}

.userandgroup-assignment .ant-modal-footer button:last-child {
  width: 120px;
  height: 30px;
  background-color: var(--primaryColor);
  border: none;
  color: var(--white);
}

.userandgroup-assignment .ant-modal-footer button:first-child:hover,
.userandgroup-assignment .ant-modal-footer button:last-child:hover {
  background-color: #cccccc54 !important;
  color: var(--primaryColor) !important;
  border: 0.1px solid var(--primaryColor) !important;
}

.userandgroup-assignment {
  font-family: 'ProximaNova';
}

.userandgroup-assignment .ant-modal-footer button {
  border-radius: 2px;
}

.userandgroup-assignment .ant-modal-footer {
  height: 60px !important;
  display: flex;
  justify-content: end;
  align-items: end;
}

.userandgroup-assignment .ant-select {
  border: 0.5px solid #00000026;
  width: 100%;
  height: 30px;
  cursor: pointer;
  padding: 0px 5px;
}

.userandgroup-assignment select:focus-visible {
  border: none;
}

.userandgroup-assignment select .userandgroup-assignment select:focus-visible option {
  border-radius: 0%;
}

.userandgroup-assignment input[type='checkbox'].checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #ccc;
  background-color: #fff;
  cursor: pointer;
}

.userandgroup-assignment input[type='checkbox'].checkbox:checked {
  border-color: red;
  background-color: red;
}

.userandgroup-assignment input[type='checkbox'].checkbox::before {
  content: '';
  display: inline-block;
  width: 9px;
  height: 5px;
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  transform: rotate(315deg);
  position: relative;
  left: 2px;
  top: -8px;
}

.height-modal {
  top: calc(50% - 400px);
}

span[aria-label='close'] {
  color: #666;
}
button[aria-label='Close']:hover span span svg {
  color: var(--primaryColor);
}

.userandgroup-assignment input[type='checkbox'].checkbox:disabled {
  background-color: #8080807d !important;
  border-color: #80808000 !important;
}
.addModule input[type='checkbox'].checkbox:disabled {
    background-color: #8080807d !important;
  border-color: #80808000 !important;
}