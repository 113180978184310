.isToSearch {
  border-width: 0.2px 0.2px 0.2px 0; /* top right bottom left */
  font-size: 14px;
  border-radius: 0px;
}

.isNotToSearch {
  padding-left: 10px;
  border-width: 0.2px;
  border-radius: 5px;
}

div .search-icon {
  border-width: 0.2px 0px 0.2px 0.2px; /* top right bottom left */
}

div .search-icon,
div #search {
  border-style: solid;
  border-color: #00000021;
  outline: none !important;
}

.radio-icon {
  border-radius: 5px 0px 0px 5px;
}
